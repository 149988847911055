export const GET_CONTRACTS_TEMPLATES_REQUEST = "GET_CONTRACTS_TEMPLATES_REQUEST";
export const GET_CONTRACTS_TEMPLATES_SUCCESS = "GET_CONTRACTS_TEMPLATES_SUCCESS";

export const SHOW_CONTRACT_TEMPLATES_REQUEST = "SHOW_CONTRACT_TEMPLATES_REQUEST";
export const SHOW_CONTRACT_TEMPLATES_SUCCESS = "SHOW_CONTRACT_TEMPLATES_SUCCESS";

export const POST_CONTRACT_TEMPLATES_REQUEST = "POST_CONTRACT_TEMPLATES_REQUEST";
export const POST_CONTRACT_TEMPLATES_SUCCESS = "POST_CONTRACT_TEMPLATES_SUCCESS";

export const PUT_CONTRACT_TEMPLATES_REQUEST = "PUT_CONTRACT_TEMPLATES_REQUEST";
export const PUT_CONTRACT_TEMPLATES_SUCCESS = "PUT_CONTRACT_TEMPLATES_SUCCESS";

export const DELETE_CONTRACT_TEMPLATES_REQUEST = "DELETE_CONTRACT_TEMPLATES_REQUEST";
export const DELETE_CONTRACT_TEMPLATES_SUCCESS = "DELETE_CONTRACT_TEMPLATES_SUCCESS";
