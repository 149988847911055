export const GET_CITIZENSHIPS_REQUEST = "GET_CITIZENSHIPS_REQUEST";
export const GET_CITIZENSHIPS_SUCCESS = "GET_CITIZENSHIPS_SUCCESS";

export const SHOW_CITIZENSHIP_REQUEST = "SHOW_CITIZENSHIP_REQUEST";
export const SHOW_CITIZENSHIP_SUCCESS = "SHOW_CITIZENSHIP_SUCCESS";

export const POST_CITIZENSHIP_REQUEST = "POST_CITIZENSHIP_REQUEST";
export const POST_CITIZENSHIP_SUCCESS = "POST_CITIZENSHIP_SUCCESS";

export const PUT_CITIZENSHIP_REQUEST = "PUT_CITIZENSHIP_REQUEST";
export const PUT_CITIZENSHIP_SUCCESS = "PUT_CITIZENSHIP_SUCCESS";

export const DELETE_CITIZENSHIP_REQUEST = "DELETE_CITIZENSHIP_REQUEST";
export const DELETE_CITIZENSHIP_SUCCESS = "DELETE_CITIZENSHIP_SUCCESS";
