import * as ACTIONS from "@iso/redux/organizations/actionTypes";

const initialState = {
  organizations: {
    list: [],
    total: 0,
  },
};

function organizationsReducer(state = initialState, action) {
  const { payload, type } = action;

  switch (type) {
    case ACTIONS.GET_ORGANIZATIONS_SUCCESS: {
      return {
        ...state,
        ...{ organizations: payload },
      };
    }

    default:
      return state;
  }
}

export default organizationsReducer;
