export const GET_CONTRACTS_FIELDS_REQUEST = "GET_CONTRACTS_FIELDS_REQUEST";
export const GET_CONTRACTS_FIELDS_SUCCESS = "GET_CONTRACTS_FIELDS_SUCCESS";

export const SHOW_CONTRACT_FIELDS_REQUEST = "SHOW_CONTRACT_FIELDS_REQUEST";
export const SHOW_CONTRACT_FIELDS_SUCCESS = "SHOW_CONTRACT_FIELDS_SUCCESS";

export const POST_CONTRACT_FIELDS_REQUEST = "POST_CONTRACT_FIELDS_REQUEST";
export const POST_CONTRACT_FIELDS_SUCCESS = "POST_CONTRACT_FIELDS_SUCCESS";

export const PUT_CONTRACT_FIELDS_REQUEST = "PUT_CONTRACT_FIELDS_REQUEST";
export const PUT_CONTRACT_FIELDS_SUCCESS = "PUT_CONTRACT_FIELDS_SUCCESS";

export const DELETE_CONTRACT_FIELDS_REQUEST = "DELETE_CONTRACT_FIELDS_REQUEST";
export const DELETE_CONTRACT_FIELDS_SUCCESS = "DELETE_CONTRACT_FIELDS_SUCCESS";
