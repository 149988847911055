export const GET_LOCALITIES_REQUEST = "GET_LOCALITIES_REQUEST";
export const GET_LOCALITIES_SUCCESS = "GET_LOCALITIES_SUCCESS";
export const GET_COUNTIES_REQUEST = "GET_COUNTIES_REQUEST";
export const GET_COUNTIES_SUCCESS = "GET_COUNTIES_SUCCESS";
export const GET_LOCALITIES_ZONES_REQUEST = "GET_LOCALITIES_ZONES_REQUEST";
export const GET_LOCALITIES_ZONES_SUCCESS = "GET_LOCALITIES_ZONES_SUCCESS";

export const SHOW_LOCALITY_REQUEST = "SHOW_LOCALITY_REQUEST";
export const SHOW_LOCALITY_SUCCESS = "SHOW_LOCALITY_SUCCESS";

export const POST_LOCALITY_REQUEST = "POST_LOCALITY_REQUEST";
export const POST_LOCALITY_SUCCESS = "POST_LOCALITY_SUCCESS";

export const PUT_LOCALITY_REQUEST = "PUT_LOCALITY_REQUEST";
export const PUT_LOCALITY_SUCCESS = "PUT_LOCALITY_SUCCESS";

export const DELETE_LOCALITY_REQUEST = "DELETE_LOCALITY_REQUEST";
export const DELETE_LOCALITY_SUCCESS = "DELETE_LOCALITY_SUCCESS";
