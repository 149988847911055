import * as ACTIONS from "@iso/redux/contractsTemplates/actionTypes";

const initialState = {
  contractsTemplates: {
    list: [],
    total: 0,
  },
};

function contractstemplatesReducer(state = initialState, action) {
  const { payload, type } = action;

  switch (type) {
    case ACTIONS.GET_CONTRACTS_TEMPLATES_SUCCESS: {
      return {
        ...state,
        ...{ contractsTemplates: payload },
      };
    }

    default:
      return state;
  }
}

export default contractstemplatesReducer;
