import * as ACTIONS from "@iso/redux/contractsFields/actionTypes";

const initialState = {
  contractFields: {
    list: [],
    total: 0,
  },
};

function contractsfieldsReducer(state = initialState, action) {
  const { payload, type } = action;

  switch (type) {
    case ACTIONS.GET_CONTRACTS_FIELDS_SUCCESS: {
      return {
        ...state,
        ...{ contractFields: payload },
      };
    }

    default:
      return state;
  }
}

export default contractsfieldsReducer;
