export const GET_ORGANIZATIONS_REQUEST = "GET_ORGANIZATIONS_REQUEST";
export const GET_ORGANIZATIONS_SUCCESS = "GET_ORGANIZATIONS_SUCCESS";

export const SHOW_ORGANIZATION_REQUEST = "SHOW_ORGANIZATION_REQUEST";
export const SHOW_ORGANIZATION_SUCCESS = "SHOW_ORGANIZATION_SUCCESS";

export const POST_ORGANIZATION_REQUEST = "POST_ORGANIZATION_REQUEST";
export const POST_ORGANIZATION_SUCCESS = "POST_ORGANIZATION_SUCCESS";

export const PUT_ORGANIZATION_REQUEST = "PUT_ORGANIZATION_REQUEST";
export const PUT_ORGANIZATION_SUCCESS = "PUT_ORGANIZATION_SUCCESS";

export const DELETE_ORGANIZATION_REQUEST = "DELETE_ORGANIZATION_REQUEST";
export const DELETE_ORGANIZATION_SUCCESS = "DELETE_ORGANIZATION_SUCCESS";
