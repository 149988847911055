import * as ACTIONS from "@iso/redux/localities/actionTypes";

const initialState = {
  localities: {
    list: [],
    total: 0,
  },
  zones: {
    list: [],
    total: 0,
  },
  counties: {
    list: [],
    total: 0,
  },
};

function localitiesReducer(state = initialState, action) {
  const { payload, type } = action;

  switch (type) {
    case ACTIONS.GET_LOCALITIES_SUCCESS: {
      return {
        ...state,
        ...{ localities: payload },
      };
    }
    case ACTIONS.GET_COUNTIES_SUCCESS: {
      return {
        ...state,
        ...{ counties: payload },
      };
    }
    case ACTIONS.GET_LOCALITIES_ZONES_SUCCESS: {
      return {
        ...state,
        ...{ zones: payload },
      };
    }

    default:
      return state;
  }
}

export default localitiesReducer;
